import React, { useState, useEffect } from 'react'
import ReactShadowRoot from 'react-shadow-root'

import styles from 'bundle-text:./styles/index.css'
// import './styles/fonts.css'
import defaultData from './default.json'
import Footer from './Footer'

const App = () => {
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [data, setData] = useState(null)

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    setData(defaultData.data);
    fetch('https://www.westernu.edu/wp-json/westernu/v1/footer/', {
      method: 'get',
      headers: {
        'content-type': 'application/json'
      }
    })
      .then( res => {
        // a non-200 response code
        if (!res.ok) {
          // create error instance with HTTP status text
          const error = new Error(res.statusText)
          error.json = res.json()
          throw error
        }
        return res.json()
      })
      .then(
        (json) => {
          // set the response data
          setIsLoaded(true);
          setData(json.data);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])
  
  if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
          <ReactShadowRoot>
          <style>{styles}</style>
          <div className="tailwind">
            {data && (
            <Footer data={data} />
            )}
          </div>
          </ReactShadowRoot>
      </div>
    )
  }
}
export default App
